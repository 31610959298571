@import '../_var.less';

@atom: InputSearchTag;

.@{atom} {
    display: inline-flex;
    flex-flow: row wrap;
}

.@{atom}__tagCtn {
    display: inline-flex;
    flex-flow: row wrap;
    
    .@{atom}__tag {
        .mixin-input-search-style();
        margin-right: @dim-xs;
        padding: 7px 28px 6px 10px;
        font-size: @font-xs;
        font-weight: 400;
        cursor: pointer;
        position: relative;
        display: inline-block;
        transition: all .05s ease-out;
        text-transform: uppercase;

        &::after {
            .mixin-input-close-style()
        }
    }
}